var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('v-card',{staticClass:"py-5",attrs:{"width":"90%","max-width":"400","rounded":"xxl","color":"#0487D9"}},[_c('h2',{staticClass:"mb-2 text-center"},[_vm._v("เข้าสู่ระบบ")]),_c('v-form',{ref:"form",staticClass:"form-custom",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('p',[_vm._v("หมายเลขโทรศัพท์")]),_c('v-text-field',{ref:"form.phoneNumber",attrs:{"solo":"","flat":"","outlined":"","dense":"","required":"","counter":"","rules":[
          () => !!_vm.phoneNumber || 'โปรดเบอร์โทรศัทพ์',
          () =>
            (!!_vm.phoneNumber && _vm.phoneNumber.length === 10) ||
            'เบอร์มือถือไม่ถูกต้อง',
        ],"maxlength":"10","label":"หมายเลขโทรศัพท์","prepend-inner-icon":"mdi-phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('p',[_vm._v("รหัสผ่าน")]),_c('v-text-field',{attrs:{"solo":"","flat":"","outlined":"","dense":"","type":"password","rules":[() => !!_vm.password || 'โปรดกรอกรหัสผ่าน'],"required":"","label":"กรอกรหัสผ่าน"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"x-large":"","type":"submit","color":"#56B0DB","dark":"","width":"100%"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-gamepad-variant ")]),_vm._v(" เข้าสู่ระบบ ")],1),_c('v-btn',{attrs:{"medium":"","color":"null","dark":"","width":"100%"},on:{"click":_vm.handleRegister}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" สมัครสมาชิก | รับฟรี 100 ฿ ")],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-web"},[_c('img',{attrs:{"src":require("../assets/ll.png"),"alt":"","width":"100%","height":"100%"}})])
}]

export { render, staticRenderFns }